import FAQAccordion from "../FAQ/FAQ";
import { ForYou } from "../ForYou/ForYou";
import { Main } from "../Main/Main";
import { Me } from "../Me/Me";
import { Pains } from "../Pains/Pains";
import { Rates } from "../Rates/Rates";
import { Results } from "../Results/Results";
import { Reviews } from "../Reviews/Reviews";
import { Table } from "../Table/Table";
import { ContainerSC, WrapperSC } from "./style";
const faqs = [
  {
    question: "Сколько времени будет длиться интенсив?",
    answer: "Длительность интенсив 2 недели. Начало 5 февраля",
  },
  {
    question: " Будет ли доступ к материалам после окончания интенсив? ",
    answer: "Доступ будет представлен на 30 дней после окончания интенсив ",
  },
  {
    question: "Есть ли смысл идти, у меня опять ничего не получится?",
    answer:
      "Если начать постепенно внедрять в свою жизнь маленькие грамотные шаги, вы заметите как ваше тело и состояние будет улучшаться с каждым днем. Главное не бросить на пол пути, а для этого в интенсив будет посвящены уроки ",
  },
  {
    question:
      "У меня нет много времени, чтобы должно заниматься и уделять время на уроки?",
    answer:
      "комплексы занимают от 10 до 15 минут, а все уроки вы можете слушать в любое удобное для вас время, так как они все будут в записи",
  },
  {
    question: "Смогу ли я заниматься дома?",
    answer:
      "Да, комплексы рассчитаны больше на домашний формат, где вам понадобится только коврик (при желании, можно приобрести резинки для лучшего ощущения)",
  },
  {
    question: "Смогу ли я слушать все уроки в записи?",
    answer:
      "Да, все уроки и тренировки будут в записи, и будут выходить в 10:00 по мск ",
  },
  {
    question:
      "Мне подходит, если я ничего не понимаю в питании и у меня не было тренера?",
    answer:
      "Еще как! Так как вы поймете как именно вам питаться, расширите свой  пищевой выбор и уйдет тревожность. А на тренировках будет проговариваться вся техника",
  },
  {
    question:
      "Мне подойдет, если я занимаюсь в зале с весами, но питание и внутренние состояние хромает?",
    answer:
      "Вам также подходит интенсив. Мы будем работать комплексно: с телом, с питанием и с внутренним состоянием  ",
  },
  {
    question: "Можно ли разбить платеж?",
    answer:
      "Половину суммы вы можете внести до начала интенсива, а вторую часть в день начала интенсив  ",
  },
];

export const MainTemplate = () => {
  return (
    <WrapperSC>
      <ContainerSC>
        <Main />
        <Pains />
        <ForYou />
        <Table />
        <Results />
        <Rates />
        <Reviews />
        <Me />
        <FAQAccordion faqs={faqs} />
      </ContainerSC>
    </WrapperSC>
  );
};
